<template>
  <v-card class="mx-auto mt-5" max-width="500" :loading="loadingData">
    <v-card-title>
      <v-img
        contain
        max-width="300"
        src="../assets/logo-300.png"
        class="mx-auto"
      >
      </v-img>
    </v-card-title>

    <br />

    <h2 class="text-center">
      Inscripciones
    </h2>

    <br />

    <v-divider></v-divider>

    <v-card-title>
      Actividad
    </v-card-title>

    <v-card-text>
      <v-select
        :items="actividades"
        label="Elige la actividad"
        clearable
        v-model="indexActividad"
      ></v-select>

      <h3>Fecha:</h3>
      <p>
        {{ new Date(tableData[indexActividad].fecha).toLocaleDateString() }}
      </p>

      <h3>Precio:</h3>
      <p>{{ tableData[indexActividad].precio }} €</p>

      <h3>Descripción:</h3>
      <p>{{ tableData[indexActividad].descripcion }}</p>
    </v-card-text>

    <v-divider></v-divider>

    <div
      v-if="
        tableData[indexActividad].inscripciones >=
          tableData[indexActividad].plazas &&
          tableData[indexActividad].plazas !== 0
      "
    >
      <v-card-text>
        <v-alert dense border="left" type="warning">
          No quedan plazas libres para inscribirte, no obstante, ponte en
          <a href="https://alistelab.com/contacto/">contacto</a> con nosotros
          para obtener más información.
        </v-alert>
      </v-card-text>
    </div>

    <div v-else>
      <v-card-title>
        Datos del alumno
      </v-card-title>

      <v-card-text>
        <v-text-field
          label="Nombre*"
          v-model="inscripcion.nombre"
        ></v-text-field>

        <v-text-field
          label="Primer apellido*"
          v-model="inscripcion.primerApellido"
        ></v-text-field>

        <v-text-field
          label="Segundo apellido"
          v-model="inscripcion.segundoApellido"
        ></v-text-field>

        <v-menu
          ref="menu"
          v-model="fechaNacimiento"
          :close-on-content-click="false"
          :return-value.sync="inscripcion.fechaNacimiento"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="inscripcion.fechaNacimiento"
              label="Fecha de nacimiento*"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="inscripcion.fechaNacimiento"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="fechaNacimiento = false">
              Cancelar
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="inscripcion.fechaNacimiento = ''"
            >
              Limpiar
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu.save(inscripcion.fechaNacimiento)"
            >
              Aceptar
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-title>
        Datos de contacto
      </v-card-title>

      <v-card-text>
        <v-text-field
          label="Nombre*"
          v-model="inscripcion.nombreContacto"
        ></v-text-field>

        <v-text-field
          label="Apellidos"
          v-model="inscripcion.apellidosContacto"
        ></v-text-field>

        <v-text-field
          label="Teléfono*"
          v-model="inscripcion.telefono"
        ></v-text-field>

        <v-text-field
          label="Correo*"
          v-model="inscripcion.correo"
        ></v-text-field>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-title>
        Mensaje
      </v-card-title>

      <v-card-text>
        <v-textarea label="Mensaje" v-model="inscripcion.mensaje"></v-textarea>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text>
        <v-checkbox
          label="Acepto la política de privacidad"
          v-model="inscripcion.politicaPrivacidad"
        ></v-checkbox>
      </v-card-text>

      <v-card-text>
        <v-alert
          dismissible
          transition="scale-transition"
          v-model="alerta.mostrar"
          :type="alerta.tipo"
        >
          {{ alerta.mensaje }}
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-btn color="green" @click="crear">
          Inscribirse
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn text>
          <a href="https://alistelab.com">Volver</a>
        </v-btn>

        <v-btn text>
          <a
            href="https://alistelab.com/politica-de-privacidad/"
            target="_blank"
            >Política de privacidad</a
          >
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Inscripciones',
  data() {
    return {
      tableData: [],
      loadingData: false,
      indexActividad: 0,
      fechaNacimiento: false,
      alerta: {
        mostrar: false,
        tipo: null,
        mensaje: null,
      },
      inscripcion: {
        nombre: '',
        primerApellido: '',
        segundoApellido: '',
        fechaNacimiento: '',
        nombreContacto: '',
        apellidosContacto: '',
        correo: '',
        telefono: '',
        mensaje: '',
        politicaPrivacidad: false,
      },
    };
  },
  computed: {
    actividades: function() {
      const actividades = [];

      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].visible) {
          actividades.push({
            text: this.tableData[i].nombre,
            value: i,
          });
        }
      }

      return actividades;
    },
  },
  methods: {
    async getData() {
      try {
        this.loadingData = true;

        const res = await axios.get(process.env.VUE_APP_API_URL + '/actividades');

        this.tableData = res.data;

        this.loadingData = false;
      } catch (error) {
        this.loadingData = false;

        this.errorTableData = true;
      }
    },
    async crear() {
      try {
        this.inscripcion.idActividad = this.tableData[this.indexActividad]._id;

        this.loading = true;

        await axios.post(process.env.VUE_APP_API_URL + '/inscripciones/nuevo', this.inscripcion);

        this.loading = false;

        this.toggleDialog = false;

        this.alerta = {
          mostrar: true,
          tipo: 'success',
          mensaje: `¡Inscripción enviada! Te hemos enviado un correo electrónico confirmándote la inscripción (si usas Hotmail mira en SPAM). 
          Nos pondremos en contacto contigo para actualizarte cualquier información sobre la actividad`,
        };

        this.inscripcion = {
          nombre: '',
          primerApellido: '',
          segundoApellido: '',
          fechaNacimiento: '',
          nombreContacto: '',
          apellidosContacto: '',
          correo: '',
          telefono: '',
          mensaje: '',
          politicaPrivacidad: false,
        };
      } catch (error) {
        this.loading = false;

        this.alerta = {
          mostrar: true,
          tipo: 'error',
          mensaje: error.response.data,
        };
      }
    },
  },
  mounted() {
    this.getData();
    this.$emit('usePanel', false);
  },
};
</script>

<style>
#app {
  background-image: url(https://alistelab.com/wp-content/uploads/2018/10/slider_mini_elements-1024x499.png);
  background-repeat: repeat;
}
</style>
